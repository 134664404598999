import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Clipboard } from '@angular/cdk/clipboard';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-wallet-deposit',
  templateUrl: './wallet-deposit.component.html',
  styleUrls: ['./wallet-deposit.component.scss']
})
export class WalletDepositComponent implements OnInit {
  depositeForm: FormGroup;
  depositeidForm: FormGroup;
  minAmount: any = 500;
  loginButtonDisable = false;
  file: File = null;
  banktype: any;
  depositmethod: any;
  token: any;
  walletuser: any;
  cardImageBase64: any;
  webimage: any;
  setamount: any;
  amountdata: any;
  createid: any;
  type: any;
  depositdata: any;
  iddeposite:any;
  modalRef: BsModalRef;
  constructor(
    private _location: Location,
    private clipboard: Clipboard,
    private usersService: UsersService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
  ) {
    this.route.paramMap.subscribe(param => {
      this.type = param.get('type') // (+)Converts string 'id' to number
    });
  }
  async ngOnInit(): Promise<void> {
    this.createFrom();
    this.createamount();
    this.token = sessionStorage.getItem("token");
    this.getuser();
    var getamount = sessionStorage.getItem('depositamount');
    this.amountdata = JSON.parse(getamount);
    // console.log(this.amountdata);
    if (this.amountdata) {
      this.setamount = this.amountdata.coins;
    }
    var data = sessionStorage.getItem('details');
    this.iddeposite = JSON.parse(data);
    var data = sessionStorage.getItem('createid');
    this.createid = JSON.parse(data);

    console.log(this.createid);

    const userdata = await JSON.parse(sessionStorage.getItem('userDetails'));
    // console.log(userdata)
    if (userdata.details.manager == "PRTCLUB") {
      this.minAmount = 100;
    }
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }
  createamount() {
    this.depositeForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(this.minAmount)]],
      image: ['', [Validators.required]],
    })
  }
  createFrom() {
    this.depositeidForm = this.fb.group({
      image: ['', [Validators.required]],
    })
  }

  getuser() {
    this.usersService.getdata("getUser/" + this.token).subscribe((response: any) => {
      this.walletuser = response.doc;
      // console.log(this.walletuser);
      this.getpamentmethod(this.walletuser);
    })
  }

  getpamentmethod(data) {
    if (data) {
      var method = { type: data.type, typeId: data.typeId }
      this.usersService.postdata("getPaymentMethod/" + this.token, method).subscribe((response: any) => {
        console.log(response);
        this.depositmethod = response.doc;
      })
    }

  }


  async copyToClip(value: string) {
    this.clipboard.copy(value);
    this.toastr.success("Copied !");

  };

  addbank(data) {
    // console.log(data);
    this.banktype = data;
  }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  onChange(event) {
    let me = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.cardImageBase64 = reader.result;
      // console.log(reader.result);
      let mimeType = me.cardImageBase64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      me.webimage = mimeType;
      // console.log(me.webimage);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  depositamount() {
    if (this.depositeForm.value.amount != '') {
    } else {
      this.loginButtonDisable = false;
    }
    if (!this.depositeForm.value.amount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (this.depositeForm.value.amount < this.minAmount) {
      this.toastr.error("amount can't be less than " + this.minAmount);
      return;
    } else if (!this.banktype) {
      this.toastr.error("Please select a Payment method");
      return;
    } else if (!this.depositeForm.value.image) {
      this.toastr.error("Please Upload Payment screenshot");
      return;
    } else {
      this.loginButtonDisable = true;
      var data = new FormData();
      data.append("type", this.banktype.paymenttype);
      data.append("amount", this.depositeForm.value.amount);
      data.append("image", this.cardImageBase64);
      data.append("imagetype", this.webimage);
      data.append("managertype", this.banktype.type);
      data.append("managerId", '6298db693453531745fc9c8f');
      data.append("depositId", this.banktype._id);
      console.log(data);
      this.usersService.postdata("depositPayment/" + this.token, data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.loginButtonDisable = false;
          this.depositeForm?.reset();
          // this.toastr.success(response.message);
        }
      })
    }

  }

  depositidamount() {
    if (this.amountdata.username) {
      console.log('username');

      if (this.setamount != '') {
      } else {
        this.loginButtonDisable = false;
      }
      if (!this.setamount) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.depositeidForm.value.image) {
        this.toastr.error("Please Upload Payment screenshot");
        return;
      } else if (!this.banktype) {
        this.toastr.error("Please select a Payment method");
        return;
      } else if (this.setamount < this.minAmount) {
        this.toastr.error("amount can't be less than " + this.minAmount);
        return;

      } else {
        // this.loginButtonDisable = true;
        var data = new FormData();
        data.append("type", this.createid.type);
        data.append("typeId", this.createid.typeId);
        data.append("amount", this.setamount);
        data.append("paymentType", this.banktype.paymenttype);
        data.append("image", this.cardImageBase64);
        data.append("imagetype", this.webimage);
        data.append("depositId", this.banktype._id);
        data.append('sites', this.createid._id);
        data.append('username', this.amountdata.username);
        this.usersService.postdata("createMysites/" + this.token, data).subscribe((response: any) => {
          this.depositdata = response;
          if (response) {
            document.getElementById("openModalButton").click();
            this.loginButtonDisable = false;
            this.depositeidForm?.reset();
            // this.toastr.success(response.message);
          }
        })
      }
    } else {
      if (this.setamount != '') {
      } else {
        this.loginButtonDisable = false;
      }
      if (!this.setamount) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.depositeidForm.value.image) {
        this.toastr.error("Please Upload Payment screenshot");
        return;
      } else if (!this.banktype) {
        this.toastr.error("Please select a Payment method");
        return;
      } else if (this.setamount < this.minAmount) {
        this.toastr.error("amount can't be less than " + this.minAmount);
        return;

      } else {
        // this.loginButtonDisable = true;
        var data = new FormData();
        data.append("type", this.iddeposite.type);
        data.append("typeId", this.iddeposite.typeId);
        data.append("mysiteId", this.iddeposite._id);
        data.append("amount", this.setamount);
        data.append("paymentType", this.banktype.paymenttype);
        data.append("image", this.cardImageBase64);
        data.append("imagetype", this.webimage);
        data.append("depositId", this.banktype._id);
        this.usersService.postdata("depositInsite/" + this.token, data).subscribe((response: any) => {
          this.depositdata = response;
          if (response) {
            document.getElementById("openModalButton").click();
            this.loginButtonDisable = false;
            this.depositeidForm?.reset();
            // this.toastr.success(response.message);
          }
        })
      }
    }

  }


  ngOnDestroy() {
    sessionStorage.removeItem('depositamount');
  }

}
