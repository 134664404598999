<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="header">
            <mat-toolbar class="mat-toolbar-row">
                <div class="left-col">
                    <mat-icon (click)="backClicked()" class="seticon">keyboard_arrow_left</mat-icon>
                </div>
                <div>
                    <span class="sethead">Create Id</span>
                    <button id="openModalButton" [hidden]="true" (click)="openmodel(success)">test</button>
                </div>
            </mat-toolbar>
        </div>


        <div class="row setpayment">
            <div class="col-3">
                <img class="setidimage" src="https://acepunt.kushubmedia.com/uploads/sites/{{createid?.image}}">
            </div>
            <div class="col-7 setcolumn">
                <span>{{createid?.name}}</span><br>
                <span class="name">{{createid?.url}}</span>
            </div>

        </div>

        <div class="row paymentdetaile">

            <div class="col-6">Min Refill</div>
            <div class="col-6 text-right">1000</div>

            <div class="col-6">Min Withdrawal</div>
            <div class="col-6 text-right">1000</div>

            <div class="col-6">Min Maintaining Bal</div>
            <div class="col-6 text-right">1000</div>

            <div class="col-6">Max Withdrawal</div>
            <div class="col-6 text-right">10000000</div>

        </div>
        <div class="row setform">
        <div class="col-12">
            <form class="example-form" [formGroup]='Idform' (ngSubmit)='createidform()'>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Username" formControlName='username'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="number" class="form-control" placeholder="Deposit Coins" formControlName='coins'>
                </div>

                <div class="form-group"> 
                    <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="wallet">Pay For Wallet</mat-checkbox>
                      </section>
                </div>

                <div class="submit-btn text-center">
                    <button mat-raised-button color="primary" class="subbutton">Submit </button>
                </div>
            </form>
        </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- Success Payment -->

<ng-template #success>
    <div class="modal-body">
        <mat-list class="setmat">
            <div class="text-right"><a type="button" class="close" data-dismiss="modal">
                    <mat-icon (click)="modalRef.hide()">close</mat-icon>
                </a></div>
            <div class="text-center" *ngIf="depositdata.success == true">
                <mat-icon class="setback">done</mat-icon>
                <h5 class="setamount">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>

            </div>
            <div class="text-center" *ngIf="depositdata.success != true">
                <mat-icon class="setback1">cancel</mat-icon>
                <h5 class="setamount1">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>

            </div>
            
        </mat-list>

    </div>
</ng-template>