import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router} from "@angular/router";
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import {SidenavService} from '../services/sidenav.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  @ViewChild('changePwd', {static: true}) public privacyPopup: TemplateRef<any>;
  modalRef: BsModalRef;
  userDetails: any;
  walletBalance:number=0;
  rollBalance:number=0;
  exposure:number=0;
  casinoBal:number;
  hide:boolean=true;
  moment: any = moment;
  chngePass:number;
  submitted = false;
  tokenStatus:boolean=false;
  avaialableEventType:any;
  constructor(private router: Router,private sidenav: SidenavService,private toastr: ToastrService,private socket: Socket,private sidenavService: SidenavService,private modalService: BsModalService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
      this.tokenStatus=true;
    }
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getUserBalance() {
    this.userDetails=await this.getDetials();
    this.avaialableEventType=this.userDetails?.details?.availableEventTypes.indexOf("8080");
    
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.rollBalance = data.rolling_amount;
          this.exposure= data.exposure;
          this.getCasinoBal();
        }
       }).bind(this));
      
    }

    openCasino(gameID:string)
    {
      const data={gameId:gameID}; 
      localStorage.setItem('casinoDb',JSON.stringify(data));
      // this.route.navigate(['./casino-url']);
      this.router.navigate(["./casino-url"]);
      
    }
  getCasinoBal() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-userbalance', userdata);
    
    this.socket.on('get-balance-success',(function(data:any){
      
      if(data){
        this.casinoBal=data.amount;
      }
     }).bind(this));
    
  }

  dashboard(){
    this.router.navigate(['dashboard'])
  }
  
  inplay(){
    this.router.navigate(['inplay'])
  }

  toggleRightSidenav() {
    this.sidenav.toggle(); 
  }

  openModalResetPwd(changePwd: TemplateRef<any>) {
    if(this.tokenStatus){
      this.modalRef = this.modalService.show(
        changePwd,
        Object.assign({}, { class: 'changePwd-modal modal-lg',ignoreBackdropClick: true })
      );
    }else{
      this.router.navigate(['login']);
    }
    
  }

  set_fantacy_model()
  {
    localStorage.setItem('fantacy_model','true');
  }

  onSubmitChangePassword() {
    this.submitted = true;
    
    if (this.chngePass) {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
          
        },
        password: this.chngePass,
        targetUser: '',
      };
      
      this.socket.emit('update-password', userdata);
      
      this.socket.on('update-password-success',(function(data:any){
        
        if(data){
          this.submitted = false;
          this.modalRef.hide();
          this.toastr.success(data.message, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          setTimeout(()=>{ this.logoutUser(); },1000);
        }
       }).bind(this));
    }
    else{
       this.toastr.error('new password is req');
    }

  }
 
  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    window.location.replace('login');
  }


}
