import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router} from "@angular/router";
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import {SidenavService} from '../services/sidenav.service';

@Component({
  selector: 'app-fixed-deposit',
  templateUrl: './fixed-deposit.component.html',
  styleUrls: ['./fixed-deposit.component.scss']
})
export class FixedDepositComponent implements OnInit {
  userDetails:any;
  ListData:any;
  avaialableEventType:any;
  constructor(private router: Router,private sidenav: SidenavService,private toastr: ToastrService,private socket: Socket,private sidenavService: SidenavService,private modalService: BsModalService) { }

   ngOnInit(): void {
    const data= JSON.parse(sessionStorage.getItem('userDetails'));
    this.avaialableEventType=data?.details?.availableEventTypes.indexOf("8080");
    if(this.avaialableEventType=="-1"){
      this.router.navigate(['dashboard'])
    }
    if(data)
    {
      this.getFixedDeposit();
    }
   
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getFixedDeposit() {
    this.userDetails=await this.getDetials();
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            manager: this.userDetails.details.manager,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-fixed-deposit-list', userdata);
      
      this.socket.on('get-fixed-deposit-list-success',(function(data:any){
        console.log(data)
        if(data){
          this.ListData=data?.data;
        }
       }).bind(this));
      
    }


}
