<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <div *ngIf="lodTimer" id="overlay" style="height:100%;display:flex;align-items:center;justify-content:center;">
            <div style="font-size:7rem;" class="font-weight-bold text-warning">{{lodTimer}}</div>
        </div>

        <!-- mainClass -->
        <div class="main-card">

        <!-- internetDisscont -->
        <div *ngIf="!internetConn" class="col-12" style="margin-top:38vh;">
            <h4 class="font-weight-bold text-center text-warning">Please check your internet connection and try again</h4>
        </div>

        <!-- content -->
            <mat-card *ngIf="internetConn" fxLayout="column" class="detail-card">
                <div fxLayout="row" fxLayoutAlign="start center" class="detail-header">
                    <span class="sport-icon" fxFlex="28px"><img src="assets/icons/4.png"></span>
                    <label fxFlex="50%" style="text-align: justify;">
                        <span class="match-title">{{matchName}}</span>
                        <span class="date-time">{{(matchDate  | date :'dd-MM-yy hh:mm aa')}}</span>
                    </label>
                    <!-- <mat-icon (click)="openModalCondition(Terms)">info</mat-icon>
                    <span class="material-icons green-color" *ngIf="ringOn == true" (click)="volumeOn(0)">volume_up </span>
                    <span class="material-icons red-color" *ngIf="ringOn == false" (click)="volumeOn(1)">volume_off </span> -->
                    <mat-icon (click)="openTvDiv();this.liveScoreStatus=false">live_tv</mat-icon>
                        <mat-icon (click)="openScoreDiv();this.liveTVStatus=false;" id="scoreurl" class="livetv">score</mat-icon>
                    <!-- <mat-icon (click)="openModalAddMarket(addMarket);homematches()">add</mat-icon> -->
                </div>
                
                <!-- score board and tv -->
                <mat-card *ngIf="graphicTvUrl" style="height:345px;background-color:black;" [ngClass]="liveScoreStatus ? 'liveTV-block' : 'liveTV-none'">
                    <mat-tab-group>
                        <mat-tab label="Live Score">
                            <!-- <mat-icon class="text-warning" [ngClass]="liveScoreStatus ? 'liveTV-block' : 'liveTV-none'" (click)="openScoreDiv()">close</mat-icon> -->
                            <iframe id="fp_embed_player" [src]="graphicTvUrl" marginwidth="0" marginheight="0"
                                frameborder="0" width="100%" height="345px" scrolling="yes" allowfullscreen="allowfullscreen"
                                ></iframe>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
                <mat-card *ngIf="liveUrl" style="height:240px;" [ngClass]="liveTVStatus ? 'liveTV-block' : 'liveTV-none'">
                    <mat-tab-group>
                        <mat-tab label="Live TV" >
                            <!-- <mat-icon class="text-warning" [ngClass]="liveTVStatus ? 'liveTV-block' : 'liveTV-none'" (click)="openTvDiv()">close</mat-icon> -->
                            <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0" marginheight="0" frameborder="0"
                                width="100%" height="240px" scrolling="no" allowfullscreen="allowfullscreen" allow='autoplay'></iframe>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
                <div>
                    <mat-divider></mat-divider>
                    <div class="score-slider">
                        <div class="scoreBoard" >
                            <div class="" id="scoreBoard" *ngIf="score == true">
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="score-div">
                                            <div class="score-detail">
                                                <div class="score-title row">
                                                    <div class="col-md-6 col-6 p-0">
                                                        <h3>{{scoreBoard?.t1?.n}}
                                                            &nbsp;{{scoreBoard?.i1?.sc}}-{{scoreBoard?.i1?.wk}}
                                                            ({{scoreBoard?.i1?.ov}})
                                                        </h3>
                                                        <h3 *ngIf="scoreBoard?.i2?.ov != '0.0'">
                                                            {{scoreBoard?.t2?.n}}
                                                            &nbsp;{{scoreBoard?.i2?.sc}}-{{scoreBoard?.i2?.wk}}
                                                            ({{scoreBoard?.i2?.ov}})
                                                            
                                                        </h3>
                                                        <div *ngIf="twenty == false"> 
                                                            <div *ngIf=" scoreBoard.i3.ov != '0'">
                                                                <h3 *ngIf="scoreBoard.i3b == 't1'">{{scoreBoard?.t1?.n}}
                                                                    &nbsp;
                                                                    {{scoreBoard?.i3?.sc}}-{{scoreBoard?.i3?.wk}}
                                                                    ({{scoreBoard?.i3?.ov}})
                                                                </h3>
                                                                <h3 *ngIf="scoreBoard.i3b == 't2'">{{scoreBoard?.t2?.n}}
                                                                    &nbsp;
                                                                    {{scoreBoard?.i3?.sc}}-{{scoreBoard?.i3?.wk}}
                                                                    ({{scoreBoard?.i3?.ov}})
                                                                </h3>
                                                            </div>
                                                            <div *ngIf=" scoreBoard.i4.ov != '0'">
                                                                <span *ngIf="scoreBoard.i3b == 't1'">{{scoreBoard?.t2?.n}}
                                                                    &nbsp;
                                                                    {{scoreBoard?.i4?.sc}}-{{scoreBoard?.i4?.wk}}
                                                                    ({{scoreBoard?.i4?.ov}})
                                                                </span>
                                                                <span *ngIf="scoreBoard.i3b == 't2'">{{scoreBoard?.t1?.n}}
                                                                    &nbsp;
                                                                    {{scoreBoard?.i4?.sc}}-{{scoreBoard?.i4?.wk}}
                                                                    ({{scoreBoard?.i4?.ov}})
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-6 p-0">
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="6" class="text-left">Last 6 Ball Runs</th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6" class="green-icon ballOver text-left" > <span *ngFor="let ball of perball" [ngClass]="{'wckball':ball=='W ' , 'wckball2':ball=='W', 'wckball':ball==' W' ,
                                                                        'fourcolor':ball=='4','sixfourcolor':ball=='6','zerocolor':ball=='0',
                                                                        'ball1Color':ball=='1' || ball=='2' || ball=='3' || ball=='Wd' }">{{ball}}</span>
                                                                </td>
                                                                    
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <!-- <div class="score-table">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>BATSMEN</th>
                                                                <th>R</th>
                                                                <th>B</th>
                                                                <th>4s</th>
                                                                <th>6s</th>
                                                                <th>SR</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{{(scoreBoard?.p1 == '') ? '-' :
                                                                    (scoreBoard?.p1)}}</td>
                                                                <td>{{(scoreBoard?.b1s[0] == '')?
                                                                    '-' : (scoreBoard?.b1s[0])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b1s[1] == '')?
                                                                    '-' : (scoreBoard?.b1s[1])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b1s[2] == '')?
                                                                    '-' : (scoreBoard?.b1s[2])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b1s[3] == '')?
                                                                    '-' : (scoreBoard?.b1s[3])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.p1 == '') ? '-' :
                                                                    ((((scoreBoard?.b1s[0])/(scoreBoard?.b1s[1]))*100)| number : '0.0-2')}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{(scoreBoard?.p2 == '') ? '-' :
                                                                    (scoreBoard?.p2)}}</td>
                                                                <td>{{(scoreBoard?.b2s[0] == '')?
                                                                    '-' : (scoreBoard?.b2s[0])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b2s[1] == '')?
                                                                    '-' : (scoreBoard?.b2s[1])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b2s[2] == '')?
                                                                    '-' : (scoreBoard?.b2s[2])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.b2s[3] == '')?
                                                                    '-' : (scoreBoard?.b2s[3])}}
                                                                </td>
                                                                <td>{{(scoreBoard?.p2 == '') ? '-' :
                                                                    ((((scoreBoard?.b2s[0])/(scoreBoard?.b2s[1]))*100)| number : '0.0-2')}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <mat-carousel [autoplay]="true" interval="5000" [hideIndicators]="false">
                                            <mat-carousel-slide>
                                               
                                            </mat-carousel-slide>
                                            <mat-carousel-slide>
                                                <div class="score-detail">
                                                    <div class="score-table">
                                                        <table class="table table-main">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Target</th>
                                                                    <th>P'SHIP</th>
                                                                    <th>LAST WKT</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>{{(scoreBoard?.i2?.tr ==
                                                                        undefined) ||
                                                                        (scoreBoard?.i2?.tr == null) ||
                                                                        (scoreBoard?.i2?.tr == '') ? '-'
                                                                        : (scoreBoard?.i2?.tr) }}</td>
                                                                    <td *ngIf="scoreBoard?.pt[0] != ''">
                                                                        {{scoreBoard?.pt[0]}}({{scoreBoard?.pt[1]}})
                                                                    </td>
                                                                    <td *ngIf="scoreBoard?.pt[0] == ''">
                                                                        0(0)</td>
                                                                    <td *ngIf="scoreBoard?.lw != ''">
                                                                        {{scoreBoard?.lw}}</td>
                                                                    <td *ngIf="scoreBoard?.lw == ''">-
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
        
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th>BOWLER</th>
                                                                    <th>O</th>
                                                                    <th>M</th>
                                                                    <th>R</th>
                                                                    <th>W</th>
                                                                    <th>ECO</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>{{(scoreBoard?.bw == '') ? '-' :(scoreBoard?.bw)}}</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="6" class="text-left">Last 6 Ball Runs</th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6" class="green-icon ballOver text-left" > <span *ngFor="let ball of perball" [ngClass]="{'wckball':ball=='W ' , 'wckball2':ball=='W', 'wckball':ball==' W' ,
                                                                        'fourcolor':ball=='4','sixfourcolor':ball=='6','zerocolor':ball=='0',
                                                                        'ball1Color':ball=='1' || ball=='2' || ball=='3' || ball=='Wd' }">{{ball}}</span>
                                                                </td>
                                                                    
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </mat-carousel-slide>
                                        </mat-carousel> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-tab-group class="tab-body" class="sport-details-tab">
                    <!-- 1st tab -->
                    <mat-tab label="ALL">

                        <!-- matchodds -->
                        <mat-accordion *ngIf="matchoddMarket?.length>0 && manualMatchStatus" class="tab-body odd-detail" multi>
                            <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
                                *ngFor="let matchOddsData of matchoddMarket;trackBy: itemTrackBy;let mDTIndex=index;"
                                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                <!-- <mat-expansion-panel-header> -->
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                                        <div fxLayout="row">
                                            
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                    <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                                                       <span class="text-white">{{matchOddsData?.marketName}}</span> 
                                                       <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <button _ngcontent-otw-c46="" *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2"  [ngClass]="cashOut_value(matchOddsData) == 1 ? 'cashout-button cashout-allow' : 'cashout-button'"   (click)="cashOut(matchOddsData)"> CASHOUT </button>
                                                <div class="minMax-limit"><span>Max Bet: {{matchOddsData?.maxlimit}}</span></div>
                                            </div>
                                        </div>
                                        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                                            <label class="back">BACK</label>
                                            <label class="lay">LAY</label>
                                        </div> -->
                                    </mat-panel-title>
                                    <ng-container *ngIf="matchoddMarket?.message">
                                        <span class="text-danger text-center font-weight-bold">{{matchOddsData?.message}}</span>
                                    </ng-container>
                                
                                <!-- </mat-expansion-panel-header> -->
                                <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                                    *ngFor="let runner of matchOddsData.marketBook.runners; let i = index;">
                                    <label fxLayoutAlign="start left" fxFlex="70%"
                                        style="padding-left: 10px;">
                                        <span>{{searchRunner(matchOddsData?.runners,runner?.selectionId)}}</span>
                                        <!-- <span class="text-bold"
                                            [ngClass]="{'red':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'green':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">
                                            {{(runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))| number : '0.0-2'}}
                                        </span> -->
                                        <span class="text-bold" [ngClass]="{'red':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)>=0}">
                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)| number : '0.0-2'}}
                                        </span>
                                    </label>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="runner?.availableToBack"
                                        (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToBack
                                            !=undefined?runner?.availableToBack?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToBack
                                            !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToBack">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="runner?.availableToLay"
                                        (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToLay
                                            !=undefined?runner?.availableToLay?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToLay
                                            !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToLay">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>

                                        <div *ngIf="checkMatchOddStatus(matchOddsData,runner)" class="suspend">
                                            <div class="suspend-status">SUSPENDED</div>
                                          </div>
                                
                                    <!-- <ng-container *ngIf="checkWinTossStatus" class="suspend">
                                        <div *ngIf="matchoddMarket?.marketBook?.status!='OPEN'" class="suspend-status">SUSPENDED</div>
                                    </ng-container> -->
                                    
                                </div>
                                <!-- <div class="suspend" *ngIf="matchOddsData.status == 'SUSPENDED' || matchOddsData.status == 'CLOSED' || matchOddsData.status == 'closed' || matchOddsData.status == 'Closed'">
                                    <div class="suspend-cont"> SUSPENDED</div>
                                </div> -->
                                <!-- <div class="suspend" *ngIf="matchOddsData.status != 'SUSPENDED' && matchOddsData.is_lock == true">
                                    <div class="suspend-cont"> LOCK</div>
                                </div> -->
                            </mat-expansion-panel>

                            <!-- <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                                [expanded]="true" hideToggle>
                                <mat-expansion-panel-header>

                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxFlex="75%" >
                                            <mat-icon *ngIf="!panelOpenState2">expand_less</mat-icon>
                                            <mat-icon *ngIf="panelOpenState2">expand_more</mat-icon>
                                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                                <span>Bookmaker Market</span>
                                                <span style="background-color: #F1F1F1;padding: 5px;font-size:12px;">Min: 100 | 10k
                                                </span>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                                            <label class="back">BACK</label>
                                            <label class="lay">LAY</label>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-divider></mat-divider>
                                <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                                    *ngFor="let option of [1,2,3] ">
                                    <label fxLayoutAlign="start center" fxFlex="70%" style="padding-left: 10px;">Welsh
                                        Fire Women</label>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button" fxFlex="15%"
                                        (click)="openBetSlipBottomSheet()">
                                        <span>1.47</span>
                                        <span>4.98</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button" fxFlex="15%"
                                        (click)="openBetSlipBottomSheet()">
                                        <span>1.47</span>
                                        <span>4.98</span>
                                    </button>
                                </div>
                            </mat-expansion-panel> -->

                        </mat-accordion>

                        <!-- TowinToss -->
                        <mat-accordion *ngIf="toWinTossMarket?.length>0" class="tab-body odd-detail" multi>
                            <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
                                *ngFor="let matchOddsData of toWinTossMarket;trackBy: itemTrackBy;let mDTIndex=index;"
                                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                <!-- <mat-expansion-panel-header> -->
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                                        <div fxLayout="row">
                                            
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                    <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                                                       <span class="text-white">{{matchOddsData?.marketName}}</span> 
                                                       <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <button _ngcontent-otw-c46="" *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2" [ngClass]="cashOut_value(matchOddsData) == 1 ? 'cashout-button cashout-allow' : 'cashout-button'"  (click)="cashOut(matchOddsData)"> CASHOUT </button>
                                                <div class="minMax-limit"><span>Max Bet: {{matchOddsData?.maxlimit}}</span></div>
                                            </div>
                                        </div>
                                        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                                            <label class="back">BACK</label>
                                            <label class="lay">LAY</label>
                                        </div> -->
                                    </mat-panel-title>
                                    <ng-container *ngIf="matchoddMarket?.message">
                                        <span class="text-danger text-center font-weight-bold">{{matchOddsData?.message}}</span>
                                    </ng-container>
                                
                                <!-- </mat-expansion-panel-header> -->
                                <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                                    *ngFor="let runner of matchOddsData.marketBook.runners; let i = index;">
                                    <label fxLayoutAlign="start left" fxFlex="70%"
                                        style="padding-left: 10px;">
                                        <span>{{searchRunner(matchOddsData?.runners,runner?.selectionId)}}</span>
                                        <!-- <span class="text-bold"
                                            [ngClass]="{'red':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'green':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">
                                            {{(runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))| number : '0.0-2'}}
                                        </span> -->
                                        <span class="text-bold" [ngClass]="{'red':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)>=0}">
                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)| number : '0.0-2'}}
                                        </span>
                                    </label>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="runner?.availableToBack"
                                        (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToBack
                                            !=undefined?runner?.availableToBack?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToBack
                                            !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToBack">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="runner?.availableToLay"
                                        (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToLay
                                            !=undefined?runner?.availableToLay?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToLay
                                            !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToLay">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>
    
                                      <div *ngIf="checkWinTossStatus(matchOddsData)" class="suspend">
                                        <div class="suspend-status">SUSPENDED</div>
                                      </div>
                            
                                </div>
                                
                            </mat-expansion-panel>

                        </mat-accordion>

                        <!-- bookmaker -->
                        <mat-accordion *ngIf="bookmakerMarket?.length>0" class="tab-body odd-detail" multi>
                            <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
                                *ngFor="let matchOddsData of bookmakerMarket;trackBy: itemTrackBy;let mDTIndex=index;"
                                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                <!-- <mat-expansion-panel-header> -->
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                                        <div fxLayout="row">
                                            
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                    <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon><span class="text-white">{{matchOddsData?.marketName}}</span>
                                                     <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <button _ngcontent-otw-c46="" *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2" [ngClass]="cashOut_value(matchOddsData) == 1 ? 'cashout-button cashout-allow' : 'cashout-button'"  (click)="cashOut(matchOddsData)"> CASHOUT </button>
                                                <div class="minMax-limit"><span>Max Bet: {{matchOddsData?.maxlimit}}</span></div>
                                            </div>
                                        </div>
                                        
                                    </mat-panel-title>

                                    <ng-container *ngIf="matchoddMarket?.message">
                                        <span class="text-danger text-center font-weight-bold">{{matchOddsData?.message}}</span>
                                    </ng-container>

                                <!-- </mat-expansion-panel-header> -->
                                <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                                    *ngFor="let runner of matchOddsData.marketBook.runners;let i = index;">
                                    <label fxLayoutAlign="start left" fxFlex="70%"
                                        style="padding-left: 10px;">
                                        <span>{{searchRunner(matchOddsData?.runners,runner?.selectionId)}}</span>
                                        <span class="text-bold" [ngClass]="{'red':calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)>=0}">
                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)| number : '0.0-2'}}
                                        </span>
                                    </label>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="runner?.availableToBack" (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToBack
                                            !=undefined?runner?.availableToBack?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToBack
                                            !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToBack">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="runner?.availableToLay" (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                        <span class="odds-value">{{(runner?.availableToLay
                                            !=undefined?runner?.availableToLay?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.availableToLay
                                            !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="!runner?.availableToLay">
                                        <span class="odds-value">-</span>
                                        <span class="odds-small-value">-</span>
                                    </button>

                                    <div class="suspend" *ngIf="checkBookmakerStatus(matchOddsData,runner)">
                                        <div class="suspend-status">SUSPENDED</div>
                                    </div>
                                </div>
                               
                            </mat-expansion-panel>

                        </mat-accordion>

                        <!-- session -->
                        <mat-accordion *ngIf="sessionMarket?.length>0" class="tab-body odd-detail" multi>
                            <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
                                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                <!-- <mat-expansion-panel-header> -->
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                                        <div fxLayout="row">
                                            
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                                <span class="market-title">
                                                    <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                                    <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon><span class="text-white">Session</span>
                                                     <!-- <img src="assets/images/transparent.gif"> -->
                                                </span>
                                                <!-- <div fxLayout="row">
                                                    <div class="minMax-limit"><span>Max : 100000</span></div>
                                                </div> -->
                                            </div>
                                        </div>
                                        
                                    </mat-panel-title>

                                <!-- </mat-expansion-panel-header> -->
                                
                                <div *ngFor="let runner of sessionMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                    <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
                                    <label fxLayoutAlign="start left" fxFlex="70%"
                                        style="padding-left: 10px;" (click)="session_book(sessionBook,runner)">
                                        <span>{{runner?.marketName}}</span>
                                        <p class="font-weight-bold" *ngIf="sessionMarketExposure">
                                            <span class="red" *ngIf="sessionMarketExposure[runner.marketId]">
                                                {{sessionMarketExposure[runner.marketId]}}
                                            </span>

                                            <!-- <span *ngIf="!sessionMarketExposure[runner.marketId]">
                                           0
                                        </span> -->
                                        </p>

                                       
                                    </label>

                                    <!-- min_max_fancy -->
                                    <div [matMenuTriggerFor]="menu">                                            
                                        <i class="fas fa-info-circle text-white"></i>
                                        <mat-menu #menu="matMenu" yPosition="below" >
                                            <button mat-menu-item>Min:{{runner?.minlimit}}</button>
                                            <button mat-menu-item>Max:{{runner?.maxlimit}}</button>                                            
                                        </mat-menu>
                                    </div>

                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" (click)="setSessionValue(runner?.marketBook?.availableToLay?.price,runner?.marketId,runner?.marketName,runner?.eventName,0,runner?.marketBook?.availableToLay?.size)" 
                                        >
                                        <span class="odds-value">{{(runner?.marketBook?.availableToLay
                                            !=undefined?runner?.marketBook?.availableToLay?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.marketBook?.availableToLay
                                            !=undefined?(runner?.marketBook?.availableToLay?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" *ngIf="runner?.marketBook?.availableToLay?.length == 0">
                                        <span>-</span>
                                        <span>-</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                                        fxFlex="17%" 
                                        (click)="setSessionValue(runner?.marketBook?.availableToBack?.price,runner?.marketId,runner?.marketName,runner?.eventName,1,runner?.marketBook?.availableToBack?.size)">
                                        <span class="odds-value">{{(runner?.marketBook?.availableToBack
                                            !=undefined?runner?.marketBook?.availableToBack?.price :'')}}</span>
                                        <span class="odds-small-value">{{(runner?.marketBook?.availableToBack
                                            !=undefined?(runner?.marketBook?.availableToBack?.size ) :'')}}</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                                        fxFlex="17%" *ngIf="runner?.marketBook?.availableToBack?.length == 0">
                                        <span>-</span>
                                        <span>-</span>
                                    </button>

                                    <div class="suspend" *ngIf="runner?.marketBook?.statusLabel=='Ball Running'">
                                        <div class="suspend-status">Ball runnig</div>
                                    </div>
                                    <div class="suspend"
                                        *ngIf="runner?.marketBook?.statusLabel==='SUSPENDED' && runner?.marketBook?.status!='OPEN'">
                                        <div class="suspend-status">SUSPENDED</div>
                                    </div>
                                    
                                </div>
                                <!-- fancy_message -->
                                <p class="runmessagenew" style="color:red;padding: 0 4px; margin: 0px;text-align:right;font-weight: bold;border-bottom:1px solid #aaa;font-size:12px;">{{runner?.remark}}</p>
                                </div>
                                
                            </mat-expansion-panel>

                            <!-- <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                                [expanded]="true" hideToggle>
                                <mat-expansion-panel-header>

                                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxFlex="75%" >
                                            <mat-icon *ngIf="!panelOpenState2">expand_less</mat-icon>
                                            <mat-icon *ngIf="panelOpenState2">expand_more</mat-icon>
                                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                                <span>Bookmaker Market</span>
                                                <span style="background-color: #F1F1F1;padding: 5px;font-size:12px;">Min: 100 | 10k
                                                </span>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                                            <label class="back">BACK</label>
                                            <label class="lay">LAY</label>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-divider></mat-divider>
                                <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                                    *ngFor="let option of [1,2,3] ">
                                    <label fxLayoutAlign="start center" fxFlex="70%" style="padding-left: 10px;">Welsh
                                        Fire Women</label>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="back-button" fxFlex="15%"
                                        (click)="openBetSlipBottomSheet()">
                                        <span>1.47</span>
                                        <span>4.98</span>
                                    </button>
                                    <button fxLayout="column" fxLayoutAlign="center center" class="lay-button" fxFlex="15%"
                                        (click)="openBetSlipBottomSheet()">
                                        <span>1.47</span>
                                        <span>4.98</span>
                                    </button>
                                </div>
                            </mat-expansion-panel> -->
                        </mat-accordion>
                      

                    </mat-tab>
                   <!-- 2nd tab -->
                   <mat-tab label="BETS ({{allBetDataLength == '0' ? '0' : allBetDataLength}})" >
                    <div class="betList" *ngIf="allBetDataLength != 0">
                      <ng-container *ngFor="let bet of allBetData;let i= index;">
                        <div style="margin:1px;" class="list" fxLayout="column" fxLayoutAlign="center space-around "
                            [ngClass]="{'lay-rate':(bet?.type)=='Lay','back-rate':(bet?.type)=='Back'}">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span>Selection Name :{{bet?.selectionName}}</span>
                                <span>Odds: {{bet?.marketName}}</span>

                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span *ngIf="(bet?.type)=='Lay'"
                                    [ngClass]="{'pink':(bet?.type)=='Lay','blue':(bet?.type)=='Back'}">Lay</span>
                                <span *ngIf="(bet?.type)=='Back'"
                                    [ngClass]="{'pink':(bet?.type)=='Lay','blue':(bet?.type)=='Back'}">Back</span>
                                <span>Stack: {{bet?.stake}}</span>

                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span>{{moment(bet?.placedTime).utcOffset("+05:30").format('YYYY-MM-DD hh:mm a')}}</span>
                                <span>P&L: <a>{{(bet?.rate)| number : '0.0-2'}}</a></span>
                            </div>
                            <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span>{{bet?.marketName}}</span>
                                <span *ngIf="bet.delete_status =='2'">Status: <a><button class="btn btn-danger">Void</button></a></span>
                            </div> -->
                        </div>
                    </ng-container>
                    </div>
                    <div class="text-warning" *ngIf="allBetDataLength == 0">No Bet Found</div>
                </mat-tab>
                   <!-- 2ndtab end -->
                </mat-tab-group>
            </mat-card>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #addMarket>
    <div class="modal-header">
        <h4 class="modal-title">Market List</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><mat-icon>clear</mat-icon></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="sport-market-list" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
                <h3><img src="assets/icons/{{homeMatch?.key}}.png">  {{homeMatch?.key}}</h3>
                <table class="table">
                    <tbody>
                        <tr  *ngFor="let matchData of homeMatch.value|orderBy : 'match_date';let j = index;" (click)="addOtherMarket(matchData)">
                            <td class="market-name">
                                <div class="event-info">
                                    <div class="event-format-strip">
                                        <div *ngIf="matchData?.inplay == true" class="inplay-div">
                                            <div class="inplay-btn inplay"><span>InPlay</span> </div>
                                        </div>
                                        <div class="event-full-date">									
                                            <span class="date">{{moment(matchData?.match_date).utcOffset("+05:30").format('D')}} {{moment(matchData?.match_date).utcOffset("+05:30").format('MMM')}} {{moment(matchData?.match_date).utcOffset("+05:30").format('HH:mm')}} IST</span>
                                        </div>
                                        <span class="event-format-series">{{matchData?.series_name}}</span>
                                    </div>
                                    <div class="event-team">
                                        <div class="team-name">{{matchData?.match_name}}</div>
                                    </div>
                                </div>
                                
                            </td>
                            <td class="text-right" ><mat-icon>add</mat-icon></td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
          
        </div>
    </div>

    <!-- <div class="modal-footer">
        <a class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div> -->
</ng-template>

<ng-template #bets>
    <div class="modal-header">
        <h4 class="modal-title">Bet List of "{{selectedMarket}}"</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <div class="betFilter">
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="allBetRadio" [checked]="true"
                        value="all" [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="allBetRadio">All</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="backBet" value="1"
                        [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="backBet">Back</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="layBet" value="0"
                        [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="layBet">Lay</label>
                </div>
                <!-- <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="deleteBet">
                    <label class="form-check-label" for="deleteBet">Delete Bet</label>
                </div>  -->
            </div>
            <form>
                <table class="table">
                    <thead>
                        <tr>
                            <!-- <th>ID</th> -->
                            <th>Selection</th>
                            <th>Rate</th>
                            <th>Stack</th>
                            <th>P|L</th>
                            <th>Type</th>
                            <th>Placed Time</th>
                        </tr>
                        <!-- <tr>
                            <th>
                                <input type="text" placeholder="ID" name="userid" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Name" name="username" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Selection" name="selection" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Rate" name="rate" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Stack" name="stack" class="form-control">
                            </th>
                            <th>
                                -   
                            </th>
                            <th>
                                <input type="text" placeholder="Placed Time" name="placedTime" class="form-control">
                            </th>
                        </tr> -->
                    </thead>
                    <tbody *ngIf="betDataPopup == true">
                        <tr *ngFor="let bet of allPopBetData;let i = index;">
                            <!-- <td>{{i+1}}</td> -->
                            <td>{{(bet?.is_fancy == 1) ? (bet?.market_name) : (bet?.selection_name)}}</td>
                            <td>{{bet?.odds}}</td>
                            <td>{{bet?.stack}}</td>
                            <td [ngClass]="{'red':(bet?.p_l)<0,'green':(bet?.p_l)>0}">{{(bet?.p_l)| number : '0.0-2'}}
                            </td>
                            <td [ngClass]="{'lay-rate':(bet?.is_back)==0,'back-rate':(bet?.is_back)==1}">{{bet?.is_back
                                == 1 ? 'Back' : 'Lay'}}</td>
                            <td>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="betDataPopup == false">
                        <tr>
                            <td colspan="6" class="text-center">{{message}}</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success">Submit</button>
        </div>
    </div> -->
</ng-template>
<ng-template #Terms>
    <div class="modal-content custom-modal-main">
        <div class="modal-header">
            <h4 class="modal-title">Terms & Conditions</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
        </div>

        <div class="modal-body">
            <p>AAJ SE TIED MATCH <br>  ME FANCY KA LEN <br>DEN KARNGE.</p>
            <p>1.If you not accept this agreement do not place any bet.</p>
            <p>2.Cheating bets deleted automatically or manual. No Claim.</p>
            <p>3.Admin decision is final and no claim on it.</p>
            <p>4. Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is declared. In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>5.Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.</p>
            <p>6.Runs at Fall of 1st Wicket At least one delivery must be bowled, if no wickets fall bets will be void unless settlement is already determined.</p>
            <p>7.Runs at Fall of Next Wicket The total innings runs scored by a team before the fall of the specified wicket determines the result of the market. If a team declares or reaches their target then the total amassed will be the result of the market. Bets will be void should no more play take place following the intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been removed . In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>8.We do not accept manual bet.</p>
            <p>9.In case of anyone found using two different IDs logged in same IP address his winning in both account will be cancelled.</p>
            <p>10.In case of cheating and betting in unfair rates we will cancel the bet even after settling.</p>
            <p>11.Local fancy are based on Haar - Jeet.</p>
            <p>12.Incomplete session will be cancelled but complete session will be settled.</p>
            <p>13.In case of match abandoned, cancelled, no result etc. completed sessions will be settled.</p>
            <p>14.Lambi Paari : In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled.</p>
            <p>15.Advance Session,Lambi 1`st Inning Valid Only</p>
            <p>16.Total Match Four ,Total Match Siixes,Total Match Runs,Total Match Wides,Total Match Wicket. If Over Gets Less Then All BET Will Cancel This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game</p>
            <p>17.1st Over Total Runs Prices will be offered for the total runs scored during the 1st over of the match. Extras and penalty runs will be included. The over must be completed for bets to stand unless settlement is already determined.</p>
            <p>18.Limited Overs Matches - Individual Batsmen Runs or Partnerships In a limited overs match where an individual batsman or partnership runs are traded in-play and the innings is curtailed or subject to any reduction in overs, then these markets will be settled at the midpoint of the last available quote before the overs were reduced. If the innings resumes at a later time, a new market may be formed. If a client wants a position in the new market they are required to place a new trade. If there are any subsequent reductions in overs, exactly the same rules will continue to apply i.e. the market is settled at the midpoint of the last available quote before the overs were reduced and a new market may be formed.</p>
            <p>19.Test Matches - Individual Batsmen Runs / Partnerships All bets, open or closed, on an individual batsman or partnership runs shall be void if 50 full overs are not bowled unless one team has won, is dismissed or declares prior to that point. Bets on partnership totals make up when the next wicket falls. If a batsman in the relevant partnership retires hurt, the partnership is treated as continuing between the remaining batsman and the batsman who comes to the wicket. A partnership is also treated as being ended by the end of an innings.</p>
            <p>20.Trading bets are not allowed.</p>
            
         </div>

        <div class="modal-footer">
            <a href="javascript:void(0)" class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
        </div>
    </div>
</ng-template>

<!-- sessionBook bets -->
<ng-template #sessionBook>
    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">Run Position</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
        </a>
    </div>

    <div class="modal-body" style="height:230px">
        <div class="table-div modeltable">
            <table class="table table-bordered">
                <tr>
                    <th class="text-center text-warning" align="center">Run</th>
                    <th class="text-right text-warning" align="right">Amount</th>
                </tr>
                <tr *ngFor="let l of sessionrunnerProfit|keyvalue" [ngClass]="l.value > 0 ? 'back' : 'lay'">
                    <td align="center">{{l.key}}</td>
                    <td align="right">{{l.value}}</td>
                </tr>
            </table>
            <button style="float:right" class="bg-danger text-white" (click)="modalRef.hide()">Close</button>
        </div>
    </div>
</ng-template>