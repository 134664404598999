import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { NgxLoader }from 'ngx-http-loader';
// import { NavigationStart, Router,Event, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  appTitle: HTMLLinkElement = document.querySelector('#appTitle');
  favicon:any;
  userDetails:any;
  public loader = NgxLoader;
  // displayLoadingIndigatior:boolean=false;
  
  constructor(private socket: Socket,private router:Router) 
    { 
        this.changeIcon();
        // this.socketConn();
    }

    ngOnInit(): void {

      // this.router.events.subscribe((routerEvent: Event) =>
      // {
      //   if(routerEvent instanceof NavigationStart)
      //   {
      //     this.displayLoadingIndigatior=true;          
      //   }
      //   if(routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError)
      //   {
      //     this.displayLoadingIndigatior=false;
      //   }
      //  });
    }

    async findHostName()
    { 
      return (window.location.hostname);
    }

  async changeIcon() {
     const hostname=await this.findHostName();
     const splithostname= hostname.split('.');
     this.favicon = splithostname[0];
     this.favIcon.href = './assets/favicon/'+this.favicon+'.png';
     this.appTitle.innerHTML=this.favicon;
    }

    
    async getDetials(){
      try {
        const data=await JSON.parse(sessionStorage.getItem('userDetails'));
        return data;
      } catch (e) {
        return null;
      }
      
    }

    async getUserBalance() 
    {
      this.userDetails=await this.getDetials();
        const userdata = {
          user: {
            _id: this.userDetails._id,
            key: this.userDetails.key,
            token:this.userDetails.verifytoken,
            details: {
              username: this.userDetails.details.username,
              role: this.userDetails.details.role,
              status: this.userDetails.details.status,
            },
          }
        };
        
        this.socket.emit('get-user', userdata);
        
        this.socket.on('get-user-success',(function(data:any){
          if(data){
            console.warn('balance');
          }
         }).bind(this));
        
    }

    socketConn(){
      this.socket.on('disconnect',(function(data:any){
      if(data){
        console.warn('disconnect');
      }
     }).bind(this));

      this.socket.on('connect',(function(data:any){
          console.warn('connect');
     }).bind(this)); 
    }
  
}
