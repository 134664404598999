<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
      
        <app-header></app-header>
      	<div class="main-content referalreportpage container mt-3">
            <div class="row">
                      

                      <div class="col-6 text-center">
                        <h4 class="mt-2 font-weight-bold" style="color:#fff;">
                          Total Amount: <span *ngIf="totalBalance_profit">{{totalBalance_profit[0]?.total_profit}}</span></h4>
                      </div>
                      <div class="col-6 text-center">
                        <h4 class="mt-2 font-weight-bold" style="color:#fff;">
                          Total Hold Amount: <span *ngIf="totalBalance_profit_hold">{{-1*totalBalance_profit_hold[0]?.total_hold}}</span> </h4>
                      </div>

                      <div class="col-12 text-center">
                        <h4 class="m-0 font-weight-bold" style="color:#fff;">
                         <Button class="seatlmentbtn" type="button" (click)="total_sellment()">Settlement</Button>
                        </h4>
                      </div>
                      <div class="col-12 text-center ">
                        <h4 class="mt-4 font-weight-bold" style="color:#fff;">
                        Promo Code: Note:
                        <span style="color:red"> you will get 3% lifetime bonus if you share app with your friend circle.!</span>
                        <span  [ngClass]="selected == true ? 'coupan selected' : 'coupan '" > {{user?.details?.promoCode}} <img (click)="clipBoard(user?.details?.promoCode)" class="image-src" src="assets/icons/copy.jpg" height="20px" width="20px"></span>
                        
                        </h4>
                      </div>
                      <div class="balanceShow col-12" style=" color: #fff;">
                        
                      </div>
                      </div>
                      <div class="row"> 
                    <div class="table-div-content-show col-12" style="margin-top:20px;max-height:47vh;overflow:scroll;">
                      <table class="table" style="text-align:center;" >
                        <thead style="background-color:#3c444b ;color:#fff; font-size: 1rem;" >
                          <tr>
                            <th scope="col">Username</th>
                           
                            <th scope="col">Hold Amount</th>
                            
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody class="t-body">
                          <tr class="tr-body" *ngFor="let r of userList;let i=index;">
                            <td>
                                {{r?.username}}
                            </td>
                           
                            <td>
                              {{-1*r?.total_referal_amount_get}}
                            </td>
                            <td>
                              <div _ngcontent-serverapp-c286="" class="view bg-theme text-center rounded"><span routerLink="/referal-history/{{r?._id}}" class="text-white">HISTORY</span></div> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  
                    </div>
                  
                <!-- end -->
              </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
