import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-wallet-passbook',
  templateUrl: './wallet-passbook.component.html',
  styleUrls: ['./wallet-passbook.component.scss']
})
export class WalletPassbookComponent implements OnInit {
  user: any;
  transactions: any;
  token: any;
  detaile: any;
  pasbooketails: any;
  logo: any;
  reg_symbol: boolean = true;
  modalRef: BsModalRef;
  modalRefcancel: BsModalRef;
  cancelwithdrawForm: FormGroup;
  cancelmessage: any;
  hide: boolean = false;
  // cancelwithdrawForm:any;
  constructor(
    private usersService: UsersService,
    private router: Router,
    private _location: Location,
    private fb: FormBuilder,
    private modalService: BsModalService,
  ) { 
  }

  async ngOnInit(): Promise<void> {
    this.token = sessionStorage.getItem("token");
    const data = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.user = data.details;
    // console.log(this.user);
    this.gettransactions();
    this.createFrom();
  }
  gettransactions() {
    this.usersService.getdata("transactions/" + this.token).subscribe((response: any) => {
      // console.log(response);
      this.transactions = response.data;
    })
  }
  // hide(data) {
  //   if(data==true){
  //     this.status = false;
  //   }else{
  //     this.status = true;
  //   }
  // }

  createFrom() {
    this.cancelwithdrawForm = this.fb.group({
      reason: ['', [Validators.required, Validators.minLength(12)]],
    })
  }

  openmodal(passbookdetaile: TemplateRef<any>, detailes) {
    this.hide=false;
    this.detaile = detailes;
    // console.log(this.detaile);
    var data = { id: detailes._id }
    if (this.token) {
      this.usersService.postdata("gettransactionById/" + this.token, data).subscribe((response: any) => {
        // console.log(response);
        this.pasbooketails = response.data;
      })
    }
    this.modalRef = this.modalService.show(
      passbookdetaile,
      Object.assign({}, { class: 'passbookdetaile modal-lg' })
    );
  }

  cancelwithdraw() {
    if (this.cancelwithdrawForm.value.reason != '') {
      var data = {
        id: this.pasbooketails._id,
        remarks: this.cancelwithdrawForm.value.reason,
      }
      console.log(data);
      this.usersService.putdata("cancelWithdrawl/" + this.token, data).subscribe((response: any) => {
        // console.log(response);
        this.cancelmessage = response;
        if (response) {
          document.getElementById("openModalButton").click();
          // this.toastr.success(response.message);
        }
      });
    }

  }


  cancel(success: TemplateRef<any>, detailes) {
    this.modalRefcancel = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  backClicked() {
    // console.log('test')
    // this._location.back();
    this.router.navigate(['wallet']);
  }

  gonotification() {
    this.router.navigate(['wallet-notifications']);
  }

}
