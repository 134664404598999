<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">

        <div class="row setmainhead">
            <mat-toolbar class="mat-toolbar-row">
                <div class="left-col">
                    <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                </div>
                <div class="col">
                    <h2 class="title">Offers</h2>
                </div>
                <!-- <div class="col text-right">
                    <mat-icon (click)="gonotification()">Offers</mat-icon>
                </div> -->
            </mat-toolbar>
        </div>

        <div class="row offersection">
            <div class="col-12" *ngFor="let offer of offers">
              <div class="setocoin" (click)="deposit()">
                <img class="setoimg" src="assets/images/offers1.png">
                <h2 class="setoffer">{{offer.percentage}}% discount {{offer.description}} </h2>
                <!-- <h2 class="sethead1">{{id?.url}}</h2> -->
              </div>
              <!-- <div class="setbutton">Create Id</div> -->
            </div>
          </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>