import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-wallet-offer',
  templateUrl: './wallet-offer.component.html',
  styleUrls: ['./wallet-offer.component.scss']
})
export class WalletOfferComponent implements OnInit {
  offers:any;
  constructor(
    private _location: Location,
    private router: Router,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.getoffers();
  }
  async getoffers() {
    const user=await JSON.parse(sessionStorage.getItem('userDetails'));
    // console.log(user)
    var data = { filter: { manager: user?.details?.manager } }
    this.usersService.post_api("get-offer/", data).subscribe((response: any) => {
      // console.log(response);
      this.offers = response.data;
    })
  }

  backClicked() {
    // console.log('test')
    this._location.back();
  }
  deposit(){
    this.router.navigate(['wallet-deposit/'+'deposit']);
  }
  gonotification() {
    this.router.navigate(['wallet-notifications']);
  }
}
