<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <app-header></app-header>
    <div class="main-content">
      <div *ngIf="marquetext" class="marquee-text">
        <mat-icon> rss_feed</mat-icon>
        <marquee width="100%" direction="left">{{marquetext}}</marquee>
      </div>

   




      <div class="dashboard-page contianermobile">

        <div class="game-list-cards">
         <div style="display:none" class="row">
          
          <div class="col col-2" (click)="eventLoad(4)">
            <div class="game-list-headers "  [ngClass]="eventTypeId == '4' ? 'selectedok' : ''" >
              
              <img width="47px" src="assets/newicon/cricket.svg"> Cricket 
            </div>
            
          </div>
          <div class="col col-2" (click)="eventLoad(1)">
            <div class="game-list-headers" [ngClass]="eventTypeId == '1' ? 'selectedok' : ''">
              <img width="47px"  src="assets/newicon/football.svg" >Soccer 
            </div>
            
          </div>
          <div class="col col-2" (click)="eventLoad(2)">
            <div class="game-list-headers" [ngClass]="eventTypeId == '2' ? 'selectedok' : ''">
              <img width="47px" src="assets/newicon/tennis.svg" >Tennis 
            </div>
            
          </div>
          <div class="col col-2" (click)="eventLoad('v9')">
            <div class="game-list-headers" [ngClass]="eventTypeId == 'v9' ? 'selectedok' : ''" >
              <img width="36px"  src="assets/newicon/virtual.svg" >Virtual 
            </div>
           
          </div>
          <div class="col col-2">
            <div class="game-list-headers" routerLink="/casino">
              <img width="47px" src="assets/newicon/casino.svg" >Casino 
            </div>
           
          </div>
         </div>
          </div>
          
        <!-- virtualCricketData -->
        <ng-container >
          <div class="game-list-card" *ngIf="sportType=='inplay'">
            <div class="game-list-header">
              <img src="assets/icons/cricket.png">Virtual Cricket ({{virtualCricketData?.length}})
            </div>
            <mat-card *ngFor="let x of virtualCricketData" class="match-event-list m-1"
              (click)="virtualDetial(x.eventId);">

              <!-- <mat-divider inset></mat-divider> -->
              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn inplay" style="color:white !important;background-color:#3CB371 !important" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/cricket.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>
                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>

        <!-- Cricket -->
        <ng-container *ngIf="cricketData?.length">
          <div class="game-list-card">
            <div class="game-list-header">
              <img src="assets/icons/cricket.png">Cricket ({{cricketData?.length}})
            </div>
            <mat-card *ngFor="let x of cricketData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">

              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/cricket.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>

                    <!--tvicon -->
                    <!-- <div class="card-space">
                                            <div fxLayout="row" fxLayoutAlign="end center">
                                                <span class="live-tv-icon" *ngIf="x.marketBook.inplay == true">
                                                    <mat-icon>live_tv</mat-icon>
                                                </span>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>

        <!-- Soccer -->
        <ng-container *ngIf="soccerData?.length">
          <div class="game-list-card">
            <div class="game-list-header">
              <img src="assets/icons/Soccer.png">Soccer ({{soccerData?.length}})
            </div>
            <mat-card *ngFor="let x of soccerData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">

              <!-- <mat-divider inset></mat-divider> -->
              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/soccer.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>
                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
                <!-- Suspend Box -->
                <!-- <div class="suspend" *ngIf="homeMatch?.status == 'SUSPENDED'">
                                    <div class="suspend-status">Suspended</div>
                                </div> -->
                <!-- <div class="suspend"
                                    *ngIf="homeMatch?.status != 'SUSPENDED' && homeMatch?.is_lock == true">
                                    <div class="suspend-status">LOCK</div>
                                </div> -->
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                            <button mat-flat-button>{{homeMatch?.series_name}}</button>
                        </div> -->
            </mat-card>
          </div>
        </ng-container>

        <!-- Tennis -->
        <ng-container *ngIf="tennisData?.length">
          <div class="game-list-card">
            <div class="game-list-header">
              <img src="assets/icons/Tennis.png">Tennis ({{tennisData?.length}})
            </div>
            <mat-card *ngFor="let x of tennisData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">

              <!-- <mat-divider inset></mat-divider> -->
              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/tennis.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>
                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
                <!-- Suspend Box -->
                <!-- <div class="suspend" *ngIf="homeMatch?.status == 'SUSPENDED'">
                                    <div class="suspend-status">Suspended</div>
                                </div> -->
                <!-- <div class="suspend"
                                    *ngIf="homeMatch?.status != 'SUSPENDED' && homeMatch?.is_lock == true">
                                    <div class="suspend-status">LOCK</div>
                                </div> -->
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                            <button mat-flat-button>{{homeMatch?.series_name}}</button>
                        </div> -->
            </mat-card>
          </div>
        </ng-container>

        <!-- casinoFooter -->
        <ng-container *ngIf="tokenCheck && dataLength">
          <!-- footer -->
          <footer class="text-center text-theme footeinnerbox" style="background:#152E42;">
           
          
          
            <!-- Copyright -->
            <div *ngIf="this.logo != 'boomboombook'" class="text-center p-3"
              style="background-color: rgba(0, 0, 0, 0.2);">
              © 2023 Copyright:
              <a class="text-theme" href="#">bet24seven.com</a>
            </div>
            <!-- Copyright -->
          </footer>

          <!-- footerEnd -->

        </ng-container>

        <ng-container *ngIf="dataLength==0">
          <div class="text-warning text-center" style="margin-top:25vh;">No match</div>
        </ng-container>

      </div>

    </div>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>