import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatchDetailComponent ,FancyBetBottomSheet,BetListPopup} from './match-detail/match-detail.component';
import { LedgerComponent } from './ledger/ledger.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import {MatMenuModule} from '@angular/material/menu';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule ,BsModalService} from 'ngx-bootstrap/modal';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { MyBetsComponent } from './my-bets/my-bets.component';
import {MatCardModule} from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import { BetSlipBottomSheetComponent } from './bet-slip-bottom-sheet/bet-slip-bottom-sheet.component';
import {SidenavService} from './services/sidenav.service';
import { OrderByPipe } from './order-by.pipe';
import { Match } from '../app/model/match';
import {SearchPipe} from './pipes/search.pipe';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { CasinoComponent } from './casino/casino.component';
import {DlDateTimeDateModule, DlDateTimeInputModule, DlDateTimePickerModule} from 'angular-bootstrap-datetimepicker';
import { RentalLoginComponent } from './rental-login/rental-login.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ProfileComponent } from './profile/profile.component';
import { CasinoWalletComponent } from './casino-wallet/casino-wallet.component';
import { ReferAccComponent } from './refer-acc/refer-acc.component';
import { WalletComponent } from './wallet/wallet.component';
import { CasinoUrlComponent } from './casino-url/casino-url.component';
import { WheelSpinnerComponent } from './wheel-spinner/wheel-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { RegisterComponent } from './register/register.component';
import { LoginWithOtpComponent } from './login-with-otp/login-with-otp.component';
import { RefreshComponent } from './refresh/refresh.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthguradServiceService } from './shared/services/authgurad-service.service';
import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { VirtualDetailComponent } from './virtual-detail/virtual-detail.component';
import { environment } from 'src/environments/environment';
import { PlayerBattleListComponent } from './player-battle-list/player-battle-list.component';
import { PlayerBattleMarketComponent } from './player-battle-market/player-battle-market.component';
import { FantastyGamesComponent } from './fantasty-games/fantasty-games.component';
import { PlayerContestComponent } from './player-contest/player-contest.component';
import { WalletDepositComponent } from './wallet-deposit/wallet-deposit.component';
import { WalletWithdrawComponent } from './wallet-withdraw/wallet-withdraw.component';
import { WalletPaymentMethodComponent } from './wallet-payment-method/wallet-payment-method.component';
import { WalletWithdrawDetailComponent } from './wallet-withdraw-detail/wallet-withdraw-detail.component';
import { WalletNotificationsComponent } from './wallet-notifications/wallet-notifications.component';
import { WalletCreateIdxComponent } from './wallet-create-idx/wallet-create-idx.component';
import { WalletDepositWithdrawComponent } from './wallet-deposit-withdraw/wallet-deposit-withdraw.component';
import { WalletPassbookComponent } from './wallet-passbook/wallet-passbook.component';
import { WalletOfferComponent } from './wallet-offer/wallet-offer.component';
import { WalletIdxComponent } from './wallet-idx/wallet-idx.component';
import { ReferalReportComponent } from './referal-report/referal-report.component';
import { ReferalHistoryComponent } from './referal-history/referal-history.component';
import { FixedDepositComponent } from './fixed-deposit/fixed-deposit.component';
import { JoinFixedDepositComponent } from './join-fixed-deposit/join-fixed-deposit.component';
import { FixedDepositReportComponent } from './fixed-deposit-report/fixed-deposit-report.component';
import { BannerPopupComponent } from './banner-popup/banner-popup.component';

const Socket_Url= environment['SOCKET_ENDPOINT'];
const config: SocketIoConfig = { url: Socket_Url, options: {} };

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',second:'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
  };
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    MatchDetailComponent,
    LedgerComponent,
    MyBetsComponent,
    FancyBetBottomSheet,
    BetSlipBottomSheetComponent,
    OrderByPipe,
    BetListPopup,
    SearchPipe,
    CasinoComponent,
    RentalLoginComponent,
    ProfileComponent,
    CasinoWalletComponent,
    ReferAccComponent,
    WalletComponent,
    CasinoUrlComponent,
    WheelSpinnerComponent,
    TermsConditionComponent,
    RegisterComponent,
    LoginWithOtpComponent,
    RefreshComponent,
    VirtualDetailComponent,
    PlayerBattleListComponent,
    PlayerBattleMarketComponent,
    FantastyGamesComponent,
    PlayerContestComponent,
    WalletDepositComponent,
    WalletWithdrawComponent,
    WalletPaymentMethodComponent,
    WalletNotificationsComponent,
    WalletWithdrawDetailComponent,
    WalletCreateIdxComponent,
    WalletDepositWithdrawComponent,
    WalletPassbookComponent,
    WalletOfferComponent,
    WalletIdxComponent,
    ReferalReportComponent,
    ReferalHistoryComponent,
    FixedDepositComponent,
    JoinFixedDepositComponent,
    FixedDepositReportComponent,
    BannerPopupComponent


  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatNativeDateModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMenuModule,
    ToastrModule.forRoot({timeOut: 1000,
      positionClass: 'toast-top-right'}),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatBadgeModule,
    MatAutocompleteModule,
    DlDateTimeDateModule,
    DlDateTimeInputModule,
    DlDateTimePickerModule,
    MatProgressSpinnerModule,
    NgxHttpLoaderModule.forRoot(),
    SocketIoModule.forRoot(config)
  ],
  entryComponents:[BetListPopup],
  providers: [Match,BsModalService,SidenavService,AuthguradServiceService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
