<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
        
        <div class="header">
            <mat-toolbar class="mat-toolbar-row">
                <div class="left-col">
                    <mat-icon (click)="backClicked()">keyboard_arrow_left</mat-icon>
                </div>
                <div>
                    <span class="sethead">Withdraw Details</span>
                </div>
                <span class="example-spacer"></span>
            </mat-toolbar>
        </div>
        <div class="pagewid-detai-wrap">
        <div class="row" style="margin: 10px 0px 0px 0px" *ngFor="let detaile of withdrawmethod">
            <div class="col-12">
                <mat-expansion-panel class="panel">
                    <mat-expansion-panel-header class="panelheader">
                        <mat-panel-title class="settitle">
                            {{detaile?.name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-sectionde">
                    <div class="text-center">
                        <button class="btn btn-block btn-success" (click)="addbank(bankadd ,detaile)">
                            + 
                        </button>
                    </div>
                    <div class="row setpanel" *ngFor="let bank of detaile.withdrawns">
                        <div class="col-6 setname">
                            Name
                        </div>
                        <div class="col-6 setdetaile">
                            {{bank?.name}}
                        </div>
                        <div class="col-6 setname" *ngIf="bank.type =='bank'">
                            Account No.
                        </div>
                        <div class="col-6 setdetaile" *ngIf="bank.type =='bank'">
                            {{bank?.accnumber}}
                        </div>

                        <div class="col-6 setname" *ngIf="bank.type !='bank'">
                            UPI Number
                        </div>

                        <div class="col-6 setdetaile" *ngIf="bank.type !='bank'">
                            {{bank?.upi}}
                        </div>

                        <div class="col-6 setname" *ngIf="bank.type =='bank'">
                            IFSC
                        </div>
                        <div class="col-6 setdetaile" *ngIf="bank.type =='bank'">
                            {{bank?.ifsc}}
                        </div>
                        <div class="col-6 setname" *ngIf="bank.type =='bank'">
                            Bank Name
                        </div>
                        <div class="col-6 setdetaile" *ngIf="bank.type =='bank'">
                            {{bank?.bankName}}
                        </div>
                        <div class="col-12 setbtndiv">
                            <button class="btn btn-block btn-danger" (click)="deletebank(bank._id)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="detaile.withdrawns.length==0" class="col-12 text-center setdetaile">
                        <span>No Data Found?</span>
                    </div>  </div>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #bankadd>

    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">Please Enter Bank Details</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
        </a>
    </div>

    <div class="row setform">

        <div class="col-12" *ngIf="banktype=='Bank'">
            <form class="example-form" [formGroup]='BankForm' (ngSubmit)='Addbank()'>
                <label class="form-label">* Select Bank</label>
                <div class="form-group">
                    <select class="form-control form-control-sm" placeholder="Select Bank." formControlName='bankName'>
                        <option value="{{bank.name}}" *ngFor="let bank of bankName">{{bank.name}}</option>
                    </select>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="IFSC" formControlName='ifsc'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="number" class="form-control" placeholder="Account No." formControlName='accnumber'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Name" formControlName='name'>
                </div>

                <div class="submit-btn">
                    <button mat-raised-button color="primary" class="subbutton">Submit </button>
                </div>
            </form>
        </div>

        <div class="col-12" *ngIf="banktype!='Bank'">
            <form [formGroup]='upiForm' (ngSubmit)='Addupi()'>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="UPI No." formControlName='upino'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Name" formControlName='name'>
                </div>

                <div class="submit-btn">
                    <button mat-raised-button color="primary" class="subbutton">Submit </button>
                </div>
            </form>
        </div>

    </div>
</ng-template>