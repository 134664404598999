<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="header">
            <mat-toolbar class="mat-toolbar-row">
                <div class="left-col">
                    <mat-icon (click)="backClicked()">keyboard_arrow_left</mat-icon>
                </div>
                <div>
                    <span class="sethead" *ngIf="this.type=='withdraw'">My Id Withdraw</span>
                    <span class="sethead" *ngIf="this.type=='deposit'">My Id Deposit</span>
                    <button id="openModalButton" [hidden]="true" (click)="openmodel(success)">test</button>
                </div>
            </mat-toolbar>
        </div>


        <div class="row setpayment">
            <div class="col-3">
                <img class="setidimage" src="https://acepunt.kushubmedia.com/uploads/sites/{{createid?.sites?.image}}">
            </div>
            <div class="col-7 setcolumn">
                <span>{{createid?.sites?.name}}</span><br>
                <span class="name">{{createid?.sites?.url}}</span>
            </div>

        </div>



       <!-- Withdraw Form -->
        


        <div class="row setform" *ngIf="this.type=='withdraw'">
            <div class="col-12">
                <form class="example-form" [formGroup]='withdrawform' (ngSubmit)='createidform()'>

                    <div class="form-group" appearance="fill">
                        <input type="number" class="form-control" placeholder="Withdrawal Coins"
                            formControlName='coins'>
                    </div>

                    <div class="form-group setformgroup">
                        <!-- <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="wallet">Withdraw to Wallet</mat-checkbox>
                        <mat-checkbox class="example-margin" formControlName="account">Withdraw to Account</mat-checkbox>
                      </section> -->
                        <mat-radio-group formControlName="withdrawtype">
                            <mat-radio-button class="example-margin" value="wallet">Withdraw to
                                Wallet</mat-radio-button>
                            <mat-radio-button class="example-margin" value="account">Withdraw to
                                Account</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="submit-btn text-center">
                        <button mat-raised-button color="primary" class="subbutton">Submit </button>
                    </div>
                </form>
            </div>
        </div>

        
 <!-- Deposit Form -->


        <div class="row setform" *ngIf="this.type=='deposit'">
            <div class="col-12">
                <form class="example-form" [formGroup]='depositform' (ngSubmit)='iDdepositform()'>

                    <div class="form-group" appearance="fill">
                        <input type="number" class="form-control" placeholder="Deposit Coins"
                            formControlName='coins'>
                    </div>

                    <div class="form-group setformgroup">
                        <!-- <mat-radio-group formControlName="wallet">
                            <mat-radio-button class="example-margin" value="wallet">Pay From
                                Wallet</mat-radio-button>
                        </mat-radio-group> -->
                        <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="wallet">Pay From
                            Wallet</mat-checkbox>
                      </section>
                    </div>

                    <div class="submit-btn text-center">
                        <button mat-raised-button color="primary" class="subbutton">Submit </button>
                    </div>
                </form>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- Success Payment -->

<ng-template #success>
    <div class="modal-body">
        <mat-list class="setmat">
            <div class="text-right"><a type="button" class="close" data-dismiss="modal">
                    <mat-icon (click)="modalRef.hide()">close</mat-icon>
                </a></div>
            <div class="text-center" *ngIf="depositdata.success == true">
                <!-- <mat-icon class="setback">done</mat-icon> -->
                <img src="assets/images/success.svg" />
                <h5 class="setamount">{{depositdata?.doc1?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/wallet']">Back To Home</button>

            </div>
            <div class="text-center" *ngIf="depositdata.success != true">
                <mat-icon class="setback1">cancel</mat-icon>
                <h5 class="setamount1">{{depositdata?.doc1?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/wallet']">Back To Home</button>
            </div>
            
        </mat-list>

    </div>
</ng-template>