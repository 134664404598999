<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="header">
            <mat-toolbar class="mat-toolbar-row">
                <div class="left-col">
                    <mat-icon (click)="backClicked()">keyboard_arrow_left</mat-icon>
                </div>
                <div>
                    <span class="sethead">Payment Method</span>
                </div>
            </mat-toolbar>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 class="setcoin">Deposit Coins : 500</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form class="example-form" [formGroup]='depositeForm' (ngSubmit)='depositamount()'>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Deposit Amount</mat-label>
                        <input placeholder="Coins" type="file" formControlName='amount'>
                        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        <mat-hint>*Minimum Deposite Amount is 500 Coins</mat-hint>
                    </mat-form-field> -->
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1">Option 1</mat-radio-button>
                        <mat-radio-button value="2">Option 2</mat-radio-button>
                    </mat-radio-group>


                    <div>
                        <label class="setlabel">*Click to select screenshot</label>
                        <input class="form-control setfile" type="file" formControlName='image'
                            (change)="onChange($event)">
                    </div>
                    <div class="submit-btn">
                        <button mat-raised-button [disabled]="loginButtonDisable"><span
                                class="setspan">Submit</span></button>
                    </div>
                </form>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>