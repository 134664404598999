import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { UsersService } from '../services/users.service';
import bankName from '../data/bankName.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wallet-withdraw-detail',
  templateUrl: './wallet-withdraw-detail.component.html',
  styleUrls: ['./wallet-withdraw-detail.component.scss']
})
export class WalletWithdrawDetailComponent implements OnInit {
  show: any;
  banktype: any;
  withdrawmethod: any;
  addwithdraw: any;
  bankName: any;
  withdrawlId: any;
  token: any;
  BankForm: FormGroup;
  upiForm: FormGroup;
  modalRef: BsModalRef;
  constructor(
    private _location: Location,
    private toastr: ToastrService,
    private usersService: UsersService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.banktype = 'bank';


  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem("token")
    this.getwithdral();
    this.createFrom();
    this.createupiform();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  getwithdral() {
    this.usersService.getdata("getwithdrawnMethod/" + this.token).subscribe((response: any) => {
      this.withdrawmethod = response.data;
    })
  }

  addbank(bankadd: TemplateRef<any>, detailes) {
    this.banktype = detailes.name;
    this.withdrawlId = detailes._id;
    this.bankName = bankName;
    this.modalRef = this.modalService.show(
      bankadd,
      Object.assign({}, { class: 'bankform modal-lg' })
    );
  }

  createupiform() {
    this.upiForm = this.fb.group({
      upino: ['', [Validators.required]],
      name: ['', [Validators.required]],
    })
  }

  createFrom() {
    this.BankForm = this.fb.group({
      bankName: ['', [Validators.required]],
      ifsc: ['', [Validators.required]],
      accnumber: ['', [Validators.required]],
      name: ['', [Validators.required]],
    })
  }
  Addbank() {
    if (!this.BankForm.value.bankName) {
      return;
    } else if (!this.BankForm.value.accnumber) {
      return;
    } else if (!this.BankForm.value.ifsc) {
      return;
    } else if (!this.BankForm.value.name) {
      return;
    } else if (!this.banktype) {
      return;
    } else {
      var data = {
        accnumber: this.BankForm.value.accnumber,
        bankName: this.BankForm.value.bankName,
        ifsc: this.BankForm.value.ifsc,
        name: this.BankForm.value.name,
        type: 'bank',
        upi: "",
        withdrawlId: this.withdrawlId,
      }
      // console.log(data);
      this.usersService.postdata('withdrawalMethod/' + this.token, data).subscribe((result: any) => {
        // console.log(result);
        this.getwithdral();
        this.toastr.success(result.message);
        this.modalRef.hide();
        this.BankForm.reset();
      })
    }
  }

  Addupi() {
    if (!this.upiForm.value.name) {
      return;
    } else if (!this.upiForm.value.upino) {
      return;
    } else {
      var data = {
        accnumber: '',
        bankName: '',
        ifsc: '',
        name: this.upiForm.value.name,
        // type: 'bank',
        upi: this.upiForm.value.upino,
        withdrawlId: this.withdrawlId,
      }
      // console.log(data);
      this.usersService.postdata('withdrawalMethod/' + this.token, data).subscribe((result: any) => {
        // console.log(result);
        this.getwithdral();
        this.toastr.success(result.message);
        this.modalRef.hide();
        this.upiForm.reset();
      })
    }
  }

  deletebank(bankid) {
    var formdata = { id: bankid }
    this.usersService.putdata('deleteWithdrawlMethod/' + this.token, formdata).subscribe((result: any) => {
      // console.log(result);
      this.toastr.success(result.message);
      this.getwithdral();
    })
  }

}
