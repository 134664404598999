<div class="mobile-bottom-bar">
  <div class="bottom-menu contianermobile">
    <mat-list>
      <mat-list-item
        [routerLink]="['/dashboard/inplay']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active']"
      >
      <img width="25px"  src="assets/newicon/inplay.svg"><span>Inplay</span></mat-list-item
      >
      <mat-list-item
        [routerLink]="['/dashboard/']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active']"
      >
      <img width="25px"   src="assets/newicon/home.svg"> <span>Home</span></mat-list-item
      >
      
     
      <mat-list-item [routerLink]="['/wallet']" [routerLinkActive]="['active']">
        <img width="25px"   src="assets/newicon/wallet.svg">
        <span>Wallet</span></mat-list-item
      >
      <mat-list-item [routerLink]="['/casino']" [routerLinkActive]="['active']">
        <img width="25px"  src="assets/newicon/casino.svg"><span>Casino</span>
      </mat-list-item>
      <mat-list-item
        (click)="sidenavRight.toggle()"
        [routerLinkActive]="['active']"
      >
      <img width="25px" src="assets/newicon/user.svg"><span>User</span></mat-list-item
      >
    </mat-list>
  </div>
</div>

<mat-sidenav
  mode="side"
  #sidenavRight
  closed
  position="end"
  (click)="sidenavRight.toggle()"
  class="right-panel"
>
  <!-- <div class="sidebar-first-heading">
        <span><mat-icon>close</mat-icon></span>
        <button mat-stroked-button color="warn" (click)="logoutUser()">Logout <mat-icon>logout</mat-icon></button>
    </div> -->
  <div class="row user-profile align-items-center">
    <div class="col-xs-3">
      
    </div>
    <div class="col-xs-3 username">
      {{ userDetails?.details?.username }}
      <div class="last-login">
        <!-- <div>Last login</div>
                <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
      </div>
    </div>
    <div class="sidebar-first-heading">
      <button
        *ngIf="tokenStatus"
        mat-stroked-button
        color="warn"
        (click)="logoutUser()"
      >
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
  <div class="row" style="margin: 5px 10px">
    <div class="winning-card">
      <a style="color: #ffffff">
       
        <div>Balance</div>
        <h5>
          <b>{{ walletBalance | number : "0.0-2" }}</b>
        </h5>
        <div>Rolling Point</div>
        <h5>
          <b>{{ rollBalance | number : "0.0-2" }}</b>
        </h5>
        
      </a>
    </div>

    <div class="winning-card">
      <a style="color: #ffffff">
        
        <!-- <img src="assets/icons/events.png"> -->
        <div>Casino</div>
        <h5>
          <b>{{ casinoBal | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="exposure-card">
      
      <div>Exposure</div>
      <h5>
        <b>{{ exposure | number : "0.0-2" }}</b>
      </h5>
    </div>
  </div>
  <div _ngcontent-isi-c47="" class="livecasinoleft">
    <div _ngcontent-isi-c47="" class="title">
       <h3 _ngcontent-isi-c47="">Casino</h3>
    </div>
    <div _ngcontent-isi-c47="" class="game-slider">
       <div _ngcontent-isi-c47="" class="row mb-0">
          <div _ngcontent-isi-c47="" class="col 12 m12 flex-wrap">
             <div _ngcontent-isi-c47="" class="game-item">
                <div _ngcontent-isi-c47="" class="thumb" routerLink="/casino"><img _ngcontent-isi-c47="" loading="lazy" alt=""  src="assets/images/casino/ls1.jpg" class="responsive-img"></div>
                <div _ngcontent-isi-c47="" class="play-btn"><a _ngcontent-isi-c47="" href="javascript:void(0);" ></a></div>
             </div>
             <div _ngcontent-isi-c47="" class="game-item">
                <div _ngcontent-isi-c47="" class="thumb" (click)="openCasino('SPB-aviator')"><img _ngcontent-isi-c47="" loading="lazy" alt="" src="assets/images/casino/ls2.jpg" class="responsive-img"></div>
                <div _ngcontent-isi-c47="" class="play-btn"><a _ngcontent-isi-c47="" href="javascript:void(0);"></a></div>
             </div>
             <div _ngcontent-isi-c47="" class="game-item">
                <div _ngcontent-isi-c47="" class="thumb" routerLink="/virtual-detail/1234822733"><img _ngcontent-isi-c47="" loading="lazy" alt="" src="assets/images/casino/ls3.jpg" class="responsive-img"></div>
                <div _ngcontent-isi-c47="" class="play-btn"><a _ngcontent-isi-c47="" href="javascript:void(0);"></a></div>
             </div>
          </div>
       </div>
    </div>
 </div>


  <div class="report-menu">
    <!-- <h4>Reports</h4> -->
    <ul class="menu-list" >
      <li>
        <a class="dropdown-item" [routerLink]="['/dashboard']"
          > <img width="47px" src="assets/images/icon/homes.png" > Home</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/ledger']"
          ><img src="assets/images/icon/ledger1.png" /> Ledger</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/casino-wallet']"
          > <img width="47px" src="assets/images/icon/wallet12.png" > Casino Wallet</a
        >
      </li>
     
      <li>
        <a class="dropdown-item" [routerLink]="['/profile']"
          ><img src="assets/images/icon/profiles.png" /> Profile</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/terms-condition']"
          ><img src="assets/images/icon/terms.png" /> Terms & Conditions</a
        >
      </li>
      <li>
        <a class="dropdown-item" (click)="openModalResetPwd(changePwd)"
          ><img src="assets/images/icon/setting.png" /> Settings</a
        >
      </li>
      <li>
        <a class="dropdown-item"  href="https://t.me/WORLDBET24SEVEN"
          ><img src="assets/images/icon/telegram.png" /> Telegram Support</a
        >
      </li>
      <li>
        <a class="dropdown-item" href="https://instagram.com/bet24seven_?igshid=OGQ5ZDc2ODk2ZA=="
          ><img src="assets/images/icon/instagram.png" /> Instagram</a
        >
      </li>
      <li>
        <a class="dropdown-item" href="https://wa.me/7347444258"
          ><img src="assets/images/icon/whatsapp.png" /> Whatsapp Support</a
        >
      </li>
      <li *ngIf="tokenStatus" (click)="logoutUser()">
        <a style="color: red !important"> <mat-icon>logout</mat-icon></a> LogOut
      </li>
    </ul>
  </div>

 
</mat-sidenav>

<ng-template #changePwd>
  <div matDialogTitle>
    <h4 class="modal-title">Change Password</h4>
  </div>
  <form>
    <mat-dialog-content>
      <div class="body-inner change-pwd">
        <div class="form-group">
          <label class="form-label">New Password</label>
          <input
            [type]="hide ? 'password' : 'text'"
            name="chngePass"
            class="form-control"
            [(ngModel)]="chngePass"
            required
          />
        </div>
      </div>
      <div class="checkbox">
        <label>
          <input
            (change)="hide = !hide"
            type="checkbox"
            name="checkbox"
          /><span> show password</span>
        </label>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="text-end">
        <a
          data-dismiss="modal"
          (click)="modalRef.hide()"
          mat-raised-button
          class="btn btn-danger"
          >Cancel</a
        >
        &nbsp;&nbsp;
        <button
          (click)="onSubmitChangePassword()"
          mat-raised-button
          class="btn btn-success"
          [disabled]="submitted"
        >
          Submit
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</ng-template>
