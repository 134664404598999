import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-referal-report',
  templateUrl: './referal-report.component.html',
  styleUrls: ['./referal-report.component.scss']
})
export class ReferalReportComponent implements OnInit {
  selected:any=false;
  userDetails:any = [];
  logsettlemnt:any = [];
  totalAmount:number=0;
  loader:boolean=false;
  userList:any;
  total_statement:any;
  totalBalance_profit:any;
  user:any;
  totalBalance_profit_hold:any;
  constructor(private socket: Socket,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getDetials();
  }

  async getStorage(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      this.user=data;
      console.log(this.user)
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getDetials() {
    this.userDetails=await this.getStorage();
    this.getRefAcc();
  }

  clipBoard(val:any)
  {
    if(this.selected){
      this.selected=false;
    }
    else
    {
      this.selected=true;
    }
    
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getRefAcc() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      sort: {time: -1},
    };
   
    this.socket.emit('get-referal-userList', userdata);
    
    this.socket.on('get-referals-user-success',(function(data:any){
      console.log(data);
       this.total_statement=data.totalBalance;
       this.totalBalance_profit=data.totalBalance_profit;
       this.totalBalance_profit_hold=data.totalBalance_profit_hold;
       this.userList=data.userList;
      
     }).bind(this));
    
  }

  total_sellment()
  {
    if(confirm('Are you Sure Do Settlement'))
    {
      
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        sort: {time: -1},
      };
      this.socket.emit('settlement-referal', userdata);
    
    this.socket.on('set-referals-transfer-success',(function(data:any){
    alert(data.message)
     }).bind(this));
    }
  }
 

}
